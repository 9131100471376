import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-preloader',
    templateUrl: './preloader.component.html',
    styleUrls: ['./preloader.component.scss'],
    standalone: true
})
export class PreloaderComponent implements OnInit {

  @Input('color') color = '#382f5c';

  constructor() { }

  ngOnInit() {
  }

}
